<template>
  <div class="py-7 px-5 h-screen sign-up-bg w-full overflow-hidden">
    <div class="fixed inset-0 bg-white" style="opacity: 0.8;"></div>
    <div class="flex justify-between items-center pb-5 relative max-w-5xl m-auto" style="direction: ltr;">
      <img style="margin: 0px -20px;" src="@/assets/images/logo/ShipBlu Logo Large.png" width="214" alt="">
      <div class="block sm:hidden">
        <span @click="updateLocale(getCurrentLocaleData.lang === 'English' ? 'en' : 'ar')" class="cursor-pointer flex gap-1 items-center border border-grey border-solid rounded-full py-1 px-2">
          <img class="h-4 w-5" :src="getCurrentLocaleData.img" :alt="getCurrentLocaleData.img" />
          <span class="lang-span text-black">{{ getCurrentLocaleData.lang }}</span>
        </span>
      </div>
    </div>
    <shipblu-card class="shadow-none relative bg-white rounded-4xl p-3 max-w-full m-auto overflow-hidden" style="width: 550px;">
      <div class="max-h overflow-y-auto lg:p-12 sm:p-8 p-4">
        <div class="mb-10 flex justify-between">
          <div>
            <h1 class="mb-3">{{$t('Create your account')}}</h1>
            <span class="text-lg">{{$t('Ship Smarter.. Grow Faster')}}</span>
          </div>
          <div class="hidden sm:block">
            <span @click="updateLocale(getCurrentLocaleData.lang === 'English' ? 'en' : 'ar')" class="cursor-pointer flex gap-1 items-center border border-grey-dark border-solid rounded-full py-1 px-2">
              <img class="h-4 w-5" :src="getCurrentLocaleData.img" :alt="getCurrentLocaleData.img" />
              <span class="lang-span text-black">{{ getCurrentLocaleData.lang }}</span>
            </span>
          </div>
        </div>
        <user-sign-up :merchant="merchant" @merchant="merchant = $event"></user-sign-up>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import UserSignUp from './UserSignUp.vue'
import ar from '../../../assets/images/flags/ar.svg'
import en from '../../../assets/images/flags/en.svg'

export default {
  data () {
    return {
      pinModal: false,
      errorMessage: '',
      pinValue: '',
      successPin: '',
      merchant: {
        password: ''
      }
    }
  },
  computed: {
    getCurrentLocaleData () {
      if (this.$i18n.locale === 'en') return { lang: 'العربية', img: ar }
      else return { lang: 'English', img: en }
    }
  },
  watch: {
    successPin (val) {
      if (val === true) {
        this.pinModal = false
        this.errorMessage = ''
        this.payoutModal = false
      }
    },
    pinModal (val) {
      if (val === true) {
        this.errorMessage = ''
      }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$cookies.set('language', locale, '100y')
      localStorage.setItem('locale', locale)
      if (locale === 'ar') this.$vs.rtl = true
      else if (locale === 'en') this.$vs.rtl = false
      this.$i18n.locale = this.$cookies.get('language')
    }
  },
  components: {
    ShipbluCard,
    UserSignUp
  },
  created () {
    localStorage.setItem('locale', this.$route.params.lang)
    this.$cookies.set('language', this.$route.params.lang)
    if (this.$route.params.lang === 'en' || this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
  }
}
</script>

<style lang="scss">
.show-modal {
  visibility: visible;
  opacity: 1 !important;
  position: fixed;
  inset: 0;
  z-index: 99999;
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: all 0.3s ease;
}
.sign-up-bg {
  direction: ltr;
  background-image: url('../../../assets/images/pages/bg-sign-in.svg');
  inset: 0;
  background-size: cover;
  background-position: center;
}
.btn {
  border: none;
  color: #fff;
  background: #1C5BFE;
  border-radius: 6px;
  cursor: pointer;
  line-height: 18px;
}
.max-h {
  max-height: calc(100vh - 70px - 1.75rem - 1.75rem - 1.75rem);
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #c1c1c195; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #62626265; 
  border-radius: 6px;
}
</style>